import React, { useEffect } from "react";
import useScrollBlock from "hooks/useScrollBlock";
import { Touchable } from "ui/components";
import { ChevronBackOutline, TrashOutline } from "react-ionicons";
import { getAvatar, getLogo } from "utils/imgUri";

function InfoScreen({ active, setActive, info, name, user, handleDeleteChat }) {
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    if (active) {
      blockScroll();
    } else {
      allowScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const parts = info?.data?.mobile_users;

  const isActive = (mu) => {
    const currentDate = new Date();
    const lastSeen = new Date(mu.last_seen);
    const diff = currentDate - lastSeen;
    const diffInMinutes = diff / 1000 / 60;
    if (diffInMinutes < 2) {
      return true;
    }
    return false;
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 10,
        height: window.innerHeight,
        width: window.innerWidth,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        transform: active ? "translateX(0)" : "translateX(100%)",
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        <Touchable
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 30.9,
          }}
          action={() => {
            setActive(false);
          }}
        >
          <ChevronBackOutline
            color={"#00000"}
            height="30.9px"
            width="25px"
            style={{ verticalAlign: "middle" }}
          />
        </Touchable>
        <div
          style={{
            position: "relative",
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              marginRight: "auto",
              fontSize: 16,
              verticalAlign: "middle",
            }}
          >
            Chat info
          </h2>
        </div>
      </div>
      <img
        src={getLogo(info?.data?.company?.logo)}
        alt="company logo"
        style={{
          width: 80,
          height: 80,
          borderRadius: "50%",
          borderWidth: 0.5,
          borderColor: "rgba(0,0,0,0.5)",
          objectFit: "contain",
          alignSelf: "center",
          marginTop: 35,
        }}
      />
      <h3
        style={{
          alignSelf: "center",
          fontSize: 18,
          marginTop: 10,
        }}
      >
        {name
          ? name
          : info?.data?.company?.name
          ? info?.data?.company?.name
          : ""}
      </h3>
      <div style={{ padding: "10px 20px" }}>
        <h3 style={{ fontSize: 15, fontWeight: "bold" }}>
          {info?.data?.company ? parts?.length + 1 : parts?.length} Members
        </h3>
        <div
          style={{
            backgroundColor: "rgba(200,200,200,0.1)",
            borderRadius: 7,
          }}
        >
          {info?.data?.company && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 40,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: 30,
                  // width: 30,
                }}
              >
                <img
                  src={getLogo(info?.data?.company?.logo)}
                  alt="company logo"
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    borderWidth: 0.5,
                    borderColor: "rgba(0,0,0,0.5)",
                    objectFit: "contain",
                    marginRight: 10,
                  }}
                />
                <div
                  className="status"
                  style={{
                    position: "absolute",
                    right: 7,
                    bottom: -3,
                    height: 12,
                    width: 12,
                    background: isActive(info?.data?.company)
                      ? "#AECB2D"
                      : "#E43E3E",
                    borderRadius: "50%",
                    zIndex: 100,
                  }}
                />
              </div>
              <div
                style={{
                  height: 40,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  borderBottom: "0.5px solid rgba(0,0,0,0.1)",
                }}
              >
                <h3 style={{ fontSize: 15, margin: 0 }}>
                  {info?.data?.company.name}
                </h3>
              </div>
            </div>
          )}
          {parts?.map((mu, key) => (
            <div
              key={key}
              style={{
                display: "flex",
                alignItems: "center",
                height: 40,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: 30,
                  // width: 30,
                }}
              >
                <img
                  src={getAvatar(mu?.avatar)}
                  alt="company logo"
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    borderWidth: 0.5,
                    borderColor: "rgba(0,0,0,0.5)",
                    objectFit: "contain",
                    marginRight: 10,
                  }}
                />
                <div
                  className="status"
                  style={{
                    position: "absolute",
                    right: 7,
                    bottom: -3,
                    height: 12,
                    width: 12,
                    background: isActive(mu) ? "#AECB2D" : "#E43E3E",
                    borderRadius: "50%",
                    zIndex: 100,
                  }}
                />
              </div>
              <div
                style={{
                  height: 40,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  borderBottom:
                    key === parts.length - 1
                      ? "none"
                      : "0.5px solid rgba(0,0,0,0.1)",
                }}
              >
                <h3 style={{ fontSize: 15, margin: 0 }}>{mu?.name}</h3>
              </div>
            </div>
          ))}
        </div>
        <Touchable
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
            backgroundColor: "rgba(200,200,200,0.1)",
            borderRadius: 7,
            padding: 5,
            paddingLeft: 10,
          }}
          action={handleDeleteChat}
        >
          <TrashOutline
            color={"#C4314B"}
            title={"delete icon"}
            height="35px"
            width="25px"
            style={{ verticalAlign: "middle" }}
          />
          <h3
            style={{
              margin: 0,
              marginLeft: 10,
              color: "#20262E",
              fontSize: 15,
            }}
          >
            Delete chat
          </h3>
        </Touchable>
      </div>
    </div>
  );
}

export default InfoScreen;
