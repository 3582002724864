import React, { useEffect, useState } from "react";
import CustonHeader from "./components/CustonHeader";

function BSModal({
  state,
  setState,
  shouldRenderHeader = true,
  renderBackdrop = false,
  headerTitle,
  children,
  height = "50%",
}) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const dismiss = () => {
    setMounted(false);
    setTimeout(() => {
      setState(false);
    }, 300);
  };

  return (
    <>
      {renderBackdrop && (
        <div
          onClick={() => {
            setState(false);
          }}
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            height: "100%",
            width: "100vw",
            position: "fixed",
            left: 0,
            zIndex: 10,
          }}
        />
      )}
      <div
        className={`bs-modal ${mounted && "bs-modal-active"}`}
        style={{ height }}
      >
        {shouldRenderHeader && (
          <CustonHeader title={headerTitle} dismiss={dismiss} />
        )}

        <div className="content-container">{children}</div>
      </div>
    </>
  );
}

export default BSModal;
