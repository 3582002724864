import React, { useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import Router from "Router";

import { requestForToken, onMessageListener } from "./firebase";
import { useStore } from "store";
import { apiCall, setTokenHeader } from "api";
import { appConfig } from "appConfig";
import { Toaster } from "react-hot-toast";

function App() {
  const version = appConfig.version;
  const storeToken = useStore((state) => state.storeToken);
  const { isAuthenticated, user } = useStore((state) => state.currentUser);
  const addMessage = useStore((state) => state.addMessage);
  const fetchConversations = useStore((state) => state.fetchConversations);
  const [pushToken, setPushToken] = useState("");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const fetchUserData = useStore((state) => state.fetchUserData);

  useEffect(() => {
    if (localStorage.getItem("jwtToken")) {
      setTokenHeader(localStorage.getItem("jwtToken"));
      if (!user?.id) {
        fetchUserData();
      }
    }

    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      requestForToken(setPushToken);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      isAuthenticated &&
      pushToken &&
      apiCall.defaults.headers.common["Authorization"]
    )
      storeToken({
        device_key: pushToken,
        device_agent: window.navigator.userAgent,
      });
    //eslint-disable-next-line
  }, [pushToken, isAuthenticated]);

  onMessageListener()
    .then((payload) => {
      console.log("RECEIVE MESSAGE", payload);
      if (payload.data.messageType === "live_chat") {
        if (window.location.pathname.includes("/chats/")) {
          addMessage({
            author: {
              id: payload.data.senderId,
            },
            sender_dashboard_user_id: payload.data.senderId,
            sender_mobile_user_id: null,
            message: payload.notification.body,
            createdAt: payload.data.createdAt,
          });
        } else if (window.location.pathname === "/chats") {
          fetchConversations();
        }
      }
    })
    .catch((err) => console.log("failed: ", err));

  if (!isLoaded) return null;
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          backgroundColor: "green",
          padding: "0.1em",
          zIndex: 9999,
          color: "#FFF",
        }}
      >
        {version}
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Router />
    </>
  );
}

export default App;
