import produce from "immer";
import { getMapData } from "api/lib/maps";

const initialState = {
  mapData: {
    data: [],
    canFetchNext: false,
    fetchingNext: false,
    loading: false,
    error: null,
  },
};

export const mapDataSlice = (set, get) => ({
  mapData: initialState,
  fetchMapData: async (data) => {
    set(
      produce((state) => {
        state.mapData.loading = true;
      })
    );
    getMapData(data)
      .then((res) => {
        console.log(res.data.data);
        set(
          produce((state) => {
            state.mapData.data = res.data.data;
            state.mapData.canFetchNext = res.data.next_page_url;
          })
        );
      })
      .catch((err) => {
        console.log(err.reponse.data);
      })
      .finally(() => {
        set(
          produce((state) => {
            state.mapData.loading = false;
          })
        );
      });
  },
});
