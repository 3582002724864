import React from "react";
import { Touchable } from "..";

import trashIcon from "assets/icons/trashIcon.png";

function DeleteButton({ action = false }) {
  return (
    <Touchable className="btn-back" action={action}>
      <img
        style={{ width: 20, height: 30 }}
        src={trashIcon}
        alt="trash-button"
      />
    </Touchable>
  );
}

export default DeleteButton;
