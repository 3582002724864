import React from "react";
import { Keyword, Touchable } from "ui/components";
import { hasHomeBar } from "utils/device";

import { useStore } from "store";

import zoomLogo from "assets/icons/cal/zoomLogo.png";
import meetLogo from "assets/icons/cal/meetLogo.png";
import seenIcon from "assets/icons/seenIcon.png";

function Content({ data, contentVisible, toogleBs, refer }) {
  const { keywords } = data;

  const filters = useStore((state) => state.filters);
  const userLocation = useStore((state) => state.userLocation);
  const fetchDataByKewords = useStore((state) => state.fetchDataByKewords);

  const searchByKeyWord = (item) => {
    const params = {
      longitude: userLocation?.longitude,
      latitude: userLocation?.latitude,
      radius: filters?.radius,
      keywords: item.id,
    };
    fetchDataByKewords(refer, params);
    toogleBs(true, "key", "Search");
  };

  return (
    <div
      className="content"
      style={{
        transform: `translateY(${contentVisible ? 0 : "100%"})`,
        paddingBottom: hasHomeBar() ? "70px" : "50px",
      }}
    >
      <div style={{ display: "flex" }}>
        <h3 style={{ maxWidth: "85%" }}>{data.title}</h3>
        <div
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 20,
          }}
        >
          <img
            style={{
              height: 20,
              width: 20,
              objectFit: "contain",
              marginTop: 2,
              marginRight: 5,
              filter: "grayscale(60%)",
            }}
            src={seenIcon}
            alt="check-in"
          />
          <p style={{ padding: 0, margin: 0, color: "white", fontSize: 16 }}>
            {data.views}
          </p>
        </div>
      </div>
      <div className="content-container">
        {data.description}
        {data.links && (
          <div
            style={{
              marginTop: 20,
            }}
          >
            {Object.keys(data.links).map((i) => {
              return (
                <Touchable
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 0,
                    height: 35,
                  }}
                  action={() => {
                    window.open(data?.links[i]);
                  }}
                >
                  {i === "zoom" ? (
                    <img
                      alt="zoom logo"
                      src={zoomLogo}
                      style={{
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 10,
                      }}
                    />
                  ) : i === "google_meet" ? (
                    <img
                      alt="google meet logo"
                      src={meetLogo}
                      style={{
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 10,
                      }}
                    />
                  ) : null}
                  <p>
                    {i === "zoom"
                      ? "Zoom"
                      : i === "google_meet"
                      ? "Google Meet"
                      : null}
                  </p>
                </Touchable>
              );
            })}
          </div>
        )}
        <div className="keywords-container">
          {keywords &&
            keywords.map((i, k) => (
              <Keyword
                key={k}
                label={i.name}
                item={i}
                action={searchByKeyWord}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Content;
