import { getLocationById } from "api/lib/user";

const initialState = {
  latitude: 48.5275439,
  longitude: 8.514803,
};

export const userLocationSlice = (set, get) => ({
  userLocation: initialState,
  setUserLocation: (coords) => {
    // set(() => ({ userLocation: coords }));
    set(() => ({
      userLocation: {
        latitude: 48.5275439,
        longitude: 8.514803,
      },
    }));
  },
  fetchLocationByIp: async () => {
    getLocationById()
      .then((res) => {
        // set(() => ({
        //   userLocation: {
        //     latitude: res.data.data.latitude,
        //     longitude: res.data.longitude,
        //   },
        // }));
        console.log("res from ip location:", res);
        set(() => ({
          userLocation: {
            latitude: 48.5275439,
            longitude: 8.514803,
          },
        }));
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  },
});
