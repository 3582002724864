import React from "react";

const SettingsIcon = ({ color = "#fff", width = 30, height = 30 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40">
      <path
        fill={color}
        fillRule="evenodd"
        d="M41.219,20.1a1.708,1.708,0,0,0-1.425-1.7c-.129-.032-.268-.036-.4-.061-1.144-.2-2.284-.4-3.471-.609-.429-1.121-.856-2.236-1.294-3.374.195-.265.366-.525.56-.766.5-.63.984-1.277,1.524-1.874a2,2,0,0,0-.039-3.169c-.285-.281-.534-.6-.8-.9-1.133-1.253-1.687-1.338-3.12-.455C31.8,7.772,30.86,8.371,29.856,9a17.578,17.578,0,0,0-4.99-2.3c-.092-.481-.182-.913-.259-1.349-.148-.838-.276-1.677-.426-2.513a1.724,1.724,0,0,0-1.627-1.531,13.167,13.167,0,0,0-2.545,0,1.7,1.7,0,0,0-1.63,1.521c-.079.484-.132.977-.257,1.451a12.308,12.308,0,0,1-.474,2.353c-1.476.617-2.831,1.181-4.239,1.771-.256-.172-.555-.361-.848-.564-.731-.516-1.442-1.059-2.19-1.55a1.737,1.737,0,0,0-2.43.182c-.526.484-1.034.99-1.515,1.518a1.7,1.7,0,0,0-.179,2.421c.465.71.992,1.381,1.467,2.082.244.364.614.663.634,1.006-.615,1.479-1.179,2.833-1.757,4.214-.217.045-.432.092-.649.129-1.056.18-2.117.339-3.17.547A1.678,1.678,0,0,0,1.28,20.033a23.661,23.661,0,0,0,0,2.549,1.635,1.635,0,0,0,1.272,1.547c.3.092.617.1.919.176a21.835,21.835,0,0,1,3.2.646c.454,1.2.875,2.315,1.315,3.472-.369.464-.731.92-1.093,1.371-.445.557-.916,1.1-1.335,1.675a1.745,1.745,0,0,0,.02,2.232,20.322,20.322,0,0,0,1.607,1.8,1.651,1.651,0,0,0,2.2.307c.355-.188.689-.414,1.03-.627.8-.493,1.588-.987,2.356-1.463.861.444,1.633.887,2.444,1.248s1.656.634,2.479.941c.074.366.143.672.193.976.172.97.311,1.944.516,2.905a1.587,1.587,0,0,0,1.666,1.431c.848.036,1.7.029,2.548-.009a1.538,1.538,0,0,0,1.473-1.166c.121-.381.157-.788.246-1.179a24.589,24.589,0,0,1,.553-2.937c1.448-.595,2.8-1.155,4.211-1.733.38.259.787.528,1.185.811.66.461,1.3.952,1.972,1.393a1.7,1.7,0,0,0,2.423-.161c.531-.48,1.037-.99,1.521-1.514a1.773,1.773,0,0,0,.185-2.437c-.542-.817-1.143-1.6-1.706-2.408-.2-.286-.358-.6-.483-.8.6-1.431,1.147-2.748,1.767-4.229.541-.1,1.111-.208,1.681-.3.662-.113,1.326-.193,1.985-.327a1.754,1.754,0,0,0,1.6-1.841C41.251,21.621,41.257,20.858,41.219,20.1ZM21.294,28.8a7.434,7.434,0,0,1-7.474-7.531,7.5,7.5,0,1,1,15,.047A7.419,7.419,0,0,1,21.294,28.8Z"
        transform="translate(-1.246 -1.247)"
      />
    </svg>
  );
};

export default SettingsIcon;
