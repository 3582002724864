import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useStore } from "store";

import { TextInput, Button } from "ui/components";
import FormContainer from "../FormContainer";
import userIco from "assets/icons/profile/user.png";

const ErrorItem = ({ msg }) => {
  return (
    <p className="form-api-error" style={{ margin: 5 }}>
      *{msg}
    </p>
  );
};

function Recover() {
  let navigate = useNavigate();
  const { error } = useStore((state) => state.currentUser);

  const recoverPassword = useStore((state) => state.recoverPassword);

  const callback = () => {
    navigate("/auth/login");
  };

  const onSubmit = (values, { setSubmitting }) => {
    recoverPassword({ email: values.email }, callback);
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validate: () => {},
    onSubmit: onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="dis-container">
      <FormContainer>
        {error?.errors?.email?.map((i, k) => (
          <ErrorItem key={k.toString()} msg={i} />
        ))}
        <TextInput
          id="email"
          name="email"
          placeholder="email"
          icoLeft={userIco}
          style={{ marginBottom: "10px" }}
          inputMode="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <div style={{ width: "100%", marginTop: "10px" }}>
          <Button label="Send" action={formik.handleSubmit} />
        </div>
      </FormContainer>
    </div>
  );
}

export default Recover;
