import React from "react";
import { useStore } from "store";
import { theme } from "ui";
import { Touchable } from "ui/components";
import { SendOutline } from "react-ionicons";

function BsEditMessage({ activeMessage, handleClose, companyId }) {
  const { editMessage } = useStore((state) => state);

  const [message, setMessage] = React.useState(activeMessage.message);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const onSendMessage = () => {
    const { chat_id, id: messageId } = activeMessage;
    editMessage(chat_id, messageId, { company_id: companyId, message });
    handleClose();
  };

  return (
    <div
      style={{ padding: theme.spacing.m, paddingBottom: theme.spacing.l + 10 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <input
          type="text"
          name="message"
          id="message"
          placeholder="message"
          value={message}
          onChange={handleChange}
          multiple
          style={{
            width: message ? "100%" : "85%",
            backgroundColor: "#f9f9f9",
            borderRadius: 8,
            borderColor: "rgba(0,0,0,0.5)",
            borderWidth: 0.5,
            height: 35,
            marginLeft: 10,
            padding: 5,
            color: "black",
            marginBottom: 5,
          }}
          autoFocus={true}
          onFocus={(e) => {
            e.target.select();
          }}
        />
        {message && (
          <Touchable
            className="send-btn"
            action={onSendMessage}
            style={{
              marginLeft: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SendOutline color={"#00000"} height="25px" width="25px" />
          </Touchable>
        )}
      </div>
    </div>
  );
}

export default BsEditMessage;
