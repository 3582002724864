import React, { useState, useRef, useEffect } from "react";
import { Touchable } from "ui/components";

function SelectSearch({
  name,
  options,
  placeholder,
  value,
  onChange,
  inputVal,
  onInputValChange,
  onEndReach,
  icoLeft,
  refer,
}) {
  const ref = useRef(null);
  const [ddActive, setDdActive] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDdActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div ref={ref} className="text-input-def" style={{}}>
      <Touchable
        action={() => {
          setDdActive(true);
        }}
      >
        {icoLeft && (
          <img
            className="input-ico input-ico-left"
            src={icoLeft}
            alt="input-icon-left"
          />
        )}
        <input
          type="text"
          name={name}
          id={name}
          value={inputVal}
          placeholder={placeholder}
          onChange={(e) => {
            onInputValChange(e.target.value, refer);
          }}
          style={{ paddingLeft: icoLeft ? 32 : 10, paddingRight: 10 }}
        />
      </Touchable>
      {ddActive && options?.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: 45,
            zIndex: 100,
            backgroundColor: "white",
            width: "100%",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            maxHeight: window.innerHeight - ref.current?.offsetTop - 50,
            padding: 10,
            overflow: "auto",
            border: "1px solid black",
            borderTopWidth: 0,
          }}
          onScroll={(e) => {
            const bottom =
              e.target.scrollHeight - e.target.scrollTop <
                e.target.clientHeight + 10 &&
              e.target.scrollHeight - e.target.scrollTop >
                e.target.clientHeight - 10;
            if (bottom) {
              onEndReach(refer);
            }
          }}
        >
          {options.map((i, k) => (
            <Touchable
              key={k.toString()}
              style={{ marginBottom: 5, fontSize: 16, width: "100%" }}
              action={() => {
                onInputValChange(i.value, refer);
                onChange(i.value);
                setDdActive(false);
              }}
            >
              {i.name}
              {refer === "cities" ? "" : ", " + i.value}
              <hr className="hr-style-2" />
            </Touchable>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectSearch;
