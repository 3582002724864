import React from "react";
import { BackButton, DDItem, DropDownButton, Touchable } from "ui/components";
// import { getThumb } from "utils/imgUri";
import newChatIcon from "assets/icons/chat/newChatpng.png";
import DeleteButton from "ui/components/buttons/DeleteButton";

function Header({ setSelectMode, selectMode, count = 0, handleDeleteChat }) {
  return (
    <div className="header">
      {selectMode ? (
        <DeleteButton action={handleDeleteChat} />
      ) : (
        <BackButton styleType="light" />
      )}
      {selectMode ? (
        <div className="title">
          {count === 0 ? "select" : `${count} selected`}
        </div>
      ) : (
        <div className="title">Chats</div>
      )}
      {selectMode ? (
        <Touchable
          action={() => {
            setSelectMode(false);
          }}
        >
          <div style={{ fontSize: 16, width: "30px", color: "white" }}>
            cancel
          </div>
        </Touchable>
      ) : (
        <DropDownButton top={75}>
          <DDItem
            noBorder
            icon={newChatIcon}
            label="Select"
            action={() => {
              console.log("first");
              setSelectMode(true);
            }}
          />
        </DropDownButton>
      )}
    </div>
  );
}

export default Header;
