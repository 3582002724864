import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useStore } from "store";

import { TextInput, Button } from "ui/components";
import FormContainer from "../FormContainer";

import userIco from "assets/icons/profile/user.png";

function CompleteProfile() {
  let navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };

  const { fetching, fetched } = useStore((state) => state.currentUser);
  const user = useStore((state) => state.currentUser.user);
  const logOutUser = useStore((state) => state.logOutUser);
  const { name, surname, email } = user;

  const updateUser = useStore((state) => state.updateUser);

  const onSubmit = (values, { setSubmitting }) => {
    updateUser(values, goBack);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    logOutUser();
    goBack();
  };

  const validate = (values) => {
    console.log(values);
    const errors = {};
    if (!values.email) {
      errors.email = "email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "invalid email address";
    }
    if (!values.name) {
      errors.name = "name is required";
    } else if (values?.name?.length < 3) {
      errors.name = "Minimum name length is 3 ";
    }

    if (!values.surname) {
      errors.surname = "surname is required";
    } else if (values.surname.length < 3) {
      errors.surname = "Minimum name length is 3";
    }
    if (Object.entries(errors).length === 0) {
      return false;
    } else return errors;
  };

  const formik = useFormik({
    initialValues: {
      name,
      surname,
      email,
    },
    onSubmit: onSubmit,
    validate,
  });

  useEffect(() => {
    if (fetched) {
      formik?.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched]);

  return (
    <div
      style={{
        height: "100%",
        maxWidth: 375,
        marginLeft: "auto",
        marginRight: "auto",
      }}
      className="complete-form-container"
    >
      <FormContainer>
        <TextInput
          id="name"
          name="name"
          placeholder="name"
          icoLeft={userIco}
          style={{ marginBottom: "10px" }}
          inputMode="name"
          type="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          required={true}
        />
        <TextInput
          id="surname"
          name="surname"
          placeholder="surname"
          icoLeft={userIco}
          style={{ marginBottom: "10px" }}
          value={formik.values.surname}
          onChange={formik.handleChange}
          required={true}
        />
        <TextInput
          id="email"
          name="email"
          placeholder="email"
          icoLeft={userIco}
          style={{ marginBottom: "10px" }}
          value={formik.values.email}
          onChange={formik.handleChange}
          required={true}
        />

        <div style={{ width: "100%", marginTop: "10px" }}>
          <Button
            disabled={fetching || validate(formik.values)}
            label="Save"
            action={formik.handleSubmit}
          />
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <Button
            disabled={fetching}
            label="Change user"
            action={handleLogout}
            type={"secondary"}
          />
        </div>
      </FormContainer>
    </div>
  );
}

export default CompleteProfile;
