import React from "react";
import { palette, theme } from "ui";
import { Touchable } from "ui/components";
import { getMobileThumb, getLogo } from "utils/imgUri";

import {
  EllipsisVerticalOutline,
  CheckmarkOutline,
  CheckmarkDoneOutline,
} from "react-ionicons";

function Message({ item, user, setBsMessageOptions, setActiveMessage }) {
  const { message, author, type } = item;

  const getImageSource = () => {
    if (message.includes("https")) {
      return message;
    } else {
      if (author.id === user.id) {
        return getMobileThumb(message);
      } else {
        return getLogo(message);
      }
    }
  };

  const bg = () => {
    if (type === "image") {
      return "transparent";
    }
    return author.id === user.id ? palette.blue : "#F5F5F6";
  };

  return (
    <div
      className="message-item"
      style={{
        position: "relative",
        overflow: "visible",
        backgroundColor: bg(),
        // backgroundColor: author.id === user.id ? palette.blue : "#F5F5F6",
        borderBottomLeftRadius: author.id === user.id ? "10px" : 0,
        borderBottomRightRadius: author.id === user.id ? 0 : "10px",
        marginLeft: author.id === user.id ? "auto" : 0,
        marginRight: author.id === user.id ? 0 : "auto",
        padding:
          type === "image"
            ? 0
            : `${theme.spacing.s}px 20px ${theme.spacing.s}px 8px`,
        zIndex: 0,
      }}
    >
      {type === "image" ? (
        <img
          src={getImageSource()}
          alt="media-msg"
          style={{
            maxWidth: 150,
            maxHeight: 150,
            objectFit: "cover",
            borderBottomLeftRadius: author.id === user.id ? 10 : 0,
            borderBottomRightRadius: author.id === user.id ? 0 : 10,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        />
      ) : (
        <p
          style={{
            color: author.id === user.id ? "#F5F5F6" : "black",
          }}
        >
          {message}
        </p>
      )}
      {author.id === user.id && (
        <div
          style={{
            position: "absolute",
            bottom: type === "image" ? 0 : -3,
            right: 3,
          }}
        >
          {!item.created_at_iso ? (
            <CheckmarkOutline
              color={"white"}
              title={"status"}
              height="13px"
              width="13px"
            />
          ) : (
            <CheckmarkDoneOutline
              color={"white"}
              title={"status"}
              height="13px"
              width="13px"
            />
          )}
        </div>
      )}
      {author.id === user.id && (
        <Touchable
          action={() => {
            setBsMessageOptions(true);
            setActiveMessage(item);
          }}
          style={{
            zindex: 100,
            position: "absolute",
            top: 0,
            right: -17,
            display: "flex",
            justifyContent: "center",
            width: 20,
            height: "100%",
            alignItems: type === "image" ? "flex-end" : "center",
          }}
        >
          <EllipsisVerticalOutline
            color={"#00000"}
            height="20px"
            width="20px"
          />
        </Touchable>
      )}
    </div>
  );
}

export default Message;
