import React from "react";
import { palette, theme } from "ui";
import { Touchable } from "ui/components";

import googleLogo from "assets/icons/cal/googleLogo.png";
import appleLogo from "assets/icons/cal/appleLogo.png";
import zoomLogo from "assets/icons/cal/zoomLogo.png";
import yhooLogo from "assets/icons/cal/yahooLogo.png";
import tsfLogo from "assets/icons/cal/365Logo.png";
import teamsLogo from "assets/icons/cal/teamsLogo.png";
import outlookLogo from "assets/icons/cal/outlookLogo.png";
import defCalendarLogo from "assets/icons/cal/defCalendarLogo.png";

function CalendarEventItem({ url, title, action }) {
  return (
    <Touchable
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        width: "95%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: theme.spacing.s,
        paddingVertical: theme.spacing.m,
        borderBottomColor: palette.whiteDiv,
      }}
      action={() => {
        // window.open(url);
        action();
      }}
    >
      <img
        src={
          title === "Google"
            ? googleLogo
            : title === "Zoom"
            ? zoomLogo
            : title === "Apple"
            ? appleLogo
            : title === "Yahoo"
            ? yhooLogo
            : title === "Microsoft 365"
            ? tsfLogo
            : title === "Microsoft Teams"
            ? teamsLogo
            : title === "Outlook"
            ? outlookLogo
            : defCalendarLogo
        }
        alt="provider"
        style={{
          width: 40,
          height: 40,
          objectFit: "contain",
          marginRight: 20,
        }}
      />
      <p
        style={{
          color: palette.gray,
          fontFamily: "Segoe-UI-Semi-Light",
          fontSize: 20,
          fontWeight: "500",
        }}
      >
        {title}
      </p>
    </Touchable>
  );
}

export default CalendarEventItem;
