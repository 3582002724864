import { apiCall } from "api";

export const getCountries = (query) => {
  return apiCall.get(`/countries${query ? "?name=" + query : ""}`);
};

export const getStates = (country, query) => {
  return apiCall.get(
    `/countries/states?country=${country}${query ? "&name=" + query : ""}`
  );
};

export const getCities = (country, state, query) => {
  console.log(state);
  return apiCall.get(
    `/countries/cities?country=${country}&state=${state}${
      query ? "&name=" + query : ""
    }`
  );
};

export const getNext = (url) => {
  return apiCall.get(url);
};
