import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStore } from "store";

import Layout from "./components/Layout";
import Header from "./components/Header";
import MessageList from "./components/MessageList";
import ListItem from "./components/ListItem";
import { IOTrigger } from "ui/components";

function Chats() {
  const navigate = useNavigate();

  const [selectMode, setSelectMode] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const fetchConversations = useStore((state) => state.fetchConversations);
  const bulkDeleteConversations = useStore(
    (state) => state.bulkDeleteConversations
  );
  const fetchConversationsNext = useStore(
    (state) => state.fetchConversationsNext
  );
  const { body, canFetchNext, fetchingNext } = useStore(
    (state) => state.conversations
  );

  useEffect(() => {
    fetchConversations();
    // eslint-disable-next-line
  }, []);

  const handleNavigation = (id, cId) => {
    navigate(`/chats/${id}/${cId}`);
  };

  const callback = () => {
    setSelectMode(false);
    setSelectedList([]);
  };

  const handleDeleteChat = () => {
    if (!selectedList) return;
    bulkDeleteConversations(selectedList.join(","), callback);
  };

  return (
    <Layout>
      <Header
        handleDeleteChat={handleDeleteChat}
        selectMode={selectMode}
        setSelectMode={setSelectMode}
        count={0}
      />
      <MessageList>
        {body?.data?.map((i) => (
          <ListItem
            key={i.id.toString()}
            item={i}
            handlePress={() => {
              handleNavigation(i.id, i.company_id);
            }}
            selectMode={selectMode}
            setSelectMode={setSelectMode}
            selectedList={selectedList}
            setsSelectedList={setSelectedList}
          />
        ))}
        <IOTrigger
          canFetchNext={canFetchNext}
          fetchingNext={fetchingNext}
          fetchNext={fetchConversationsNext}
        />
      </MessageList>
    </Layout>
  );
}

export default Chats;
