import React from "react";

import { getLogo } from "utils/imgUri";

function ListItem({
  item,
  handlePress,
  setSelectMode,
  selectMode,
  selectedList,
  setsSelectedList,
}) {
  const { latest_message, created_at, company } = item;

  const handleToogleChat = (e) => {
    console.log(selectedList);
    if (e.target.checked) {
      setsSelectedList([...selectedList, item.id]);
    } else {
      setsSelectedList(selectedList.filter((e) => e !== item.id));
    }
  };

  return (
    <div className="chats-list-item" onClick={handlePress}>
      <img src={getLogo(company?.logo)} alt="company-logo" />
      <div className="content-container">
        <h2>{item.name}</h2>
        <h3>{latest_message?.message}</h3>
      </div>
      <div className="meta-container">
        <h3>
          {latest_message?.created_at ? latest_message?.created_at : created_at}
        </h3>
        {latest_message?.seen === false &&
          latest_message?.sender_dashboard_user_id && (
            <div className="unseen-indicator" />
          )}
      </div>
      {selectMode && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            style={{
              zIndex: 100,
              width: 17,
              height: 17,
              marginLeft: 10,
            }}
            type="checkbox"
            checked={selectedList.includes(item.id)}
            onChange={handleToogleChat}
          />
        </div>
      )}
    </div>
  );
}

export default ListItem;
