import React from "react";
import { toast } from "react-hot-toast";

import { PencilOutline, CopyOutline, TrashOutline } from "react-ionicons";
import { useStore } from "store";
import { theme } from "ui";
import { Touchable } from "ui/components";

function BsMessageOptions({
  activeMessage,
  handleClose,
  companyId,
  setBsEditMessage,
  setActiveMessage,
}) {
  const { deleteMessage } = useStore((state) => state);

  const onCopy = () => {
    navigator.clipboard.writeText(activeMessage.message);
    toast("Message copied to clipboard", {
      style: {
        background: "#73A580",
        color: "black",
        fontSize: "14px",
      },
    });
    setActiveMessage(null);
    handleClose();
  };

  const onDelete = () => {
    const { chat_id, id: messageId } = activeMessage;
    deleteMessage(chat_id, messageId, companyId, () => {
      toast("Message deleted", {
        style: {
          background: "#73A580",
          color: "black",
          fontSize: "14px",
        },
      });
    });
    setActiveMessage(null);
    handleClose();
  };

  const onEdit = () => {
    setBsEditMessage(true);
    handleClose();
    // setMessageOptionShow(null);
  };

  return (
    <div
      style={{ padding: theme.spacing.m, paddingBottom: theme.spacing.l + 10 }}
    >
      {activeMessage.type !== "image" && (
        <>
          <Touchable
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            action={onEdit}
          >
            <div
              style={{
                width: 40,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PencilOutline
                color={"#00000"}
                title={"edit icon"}
                height="30px"
                width="30px"
              />
            </div>
            <h3
              style={{
                margin: 0,
                marginLeft: 10,
                fontSize: 22,
                color: "#20262E",
              }}
            >
              Edit
            </h3>
          </Touchable>
          <div
            style={{
              height: 0.3,
              backgroundColor: "#20262E",
              marginVertical: theme.spacing.m,
              width: "95%",
              alignSelf: "center",
              opacity: 0.5,
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        </>
      )}
      {activeMessage.type !== "image" && (
        <>
          <Touchable
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            action={onCopy}
          >
            <div
              style={{
                width: 40,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CopyOutline
                color={"#00000"}
                title={"copy icon"}
                height="30px"
                width="30px"
              />
            </div>
            <h3
              style={{
                margin: 0,
                marginLeft: 10,
                fontSize: 22,
                color: "#20262E",
              }}
            >
              Copy
            </h3>
          </Touchable>
          <div
            style={{
              height: 0.3,
              backgroundColor: "#20262E",
              marginVertical: theme.spacing.m,
              width: "95%",
              alignSelf: "center",
              opacity: 0.5,
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        </>
      )}
      <Touchable
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        action={onDelete}
      >
        <div
          style={{
            width: 40,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TrashOutline
            color={"#C4314B"}
            title={"delete icon"}
            height="30px"
            width="30px"
          />
        </div>
        <h3
          style={{
            margin: 0,
            marginLeft: 10,
            fontSize: 22,
            color: "#20262E",
          }}
        >
          Delete
        </h3>
      </Touchable>
    </div>
  );
}

export default BsMessageOptions;
