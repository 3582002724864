import React, { useEffect, useRef, useState } from "react";

import { Touchable } from "ui/components";
import Message from "./Message";

import { AddCircleOutline } from "react-ionicons";
import { SendOutline } from "react-ionicons";

function Chat({
  user,
  messages,
  onSendPress,
  onAttachmentPress,
  onEndReached,
  choosenFile,
  mediaLoading,
  bsMessageOptions,
  setBsMessageOptions,
  setActiveMessage,
}) {
  const messageListRef = useRef(null);
  const scrollToBottom = () => {
    if (messageListRef?.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const handleScroll = (e) => {
    if (window.scrollY < 60) {
      onEndReached();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    scrollToBottom,

    [messages, messageListRef]
  );

  const [message, setMessage] = useState("");
  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const onSendMessage = () => {
    const obj = {
      text: message,
      sender_mobile_user_id: user.id,
      message,
      draft: true,
    };
    onSendPress(obj);
    setMessage("");
  };

  return (
    <div className="chat">
      <div ref={messageListRef} className="messages-list">
        {messages?.map((i, k) => (
          <Message
            key={k}
            item={i}
            user={user}
            setBsMessageOptions={setBsMessageOptions}
            setActiveMessage={setActiveMessage}
          />
        ))}
      </div>
      <div className="input-container" onScroll={handleScroll}>
        <Touchable action={onAttachmentPress}>
          <AddCircleOutline color={"#00000"} height="30px" width="30px" />
        </Touchable>
        <input
          type="text"
          name="message"
          id="message"
          placeholder="message"
          value={message}
          onChange={handleChange}
          multiple
          style={{
            width: message ? "100%" : "85%",
          }}
        />
        {message && (
          <Touchable
            className="send-btn"
            action={onSendMessage}
            style={{ marginLeft: 10 }}
          >
            <SendOutline color={"#00000"} height="25px" width="25px" />
          </Touchable>
        )}
      </div>
    </div>
  );
}

export default Chat;
