import React from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { getOriginal, getVideo } from "utils/imgUri";


function DataCoverItem({ item, action }) {
  const photoSphereRef = React.useRef();
  return (
    <div
      className="data-cover-item"
      onClick={() => {
        action(item.id);
      }}
    >
      {item?.gallery?.data ? (
        <Swiper
          allowTouchMove={false}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigation, Pagination]}
          className="mySwiper"
        >
          {item?.gallery?.data?.map((i, k) => (
            <SwiperSlide key={k} onClick={() => {}}>
              {i.image ? (
                // <img src={getOriginal(i.image)} alt="gallery-slide" />
                <>
                  {i.width / i.height === 2 ? (
                    <div
                      className="App"
                      style={{
                        height: 220,
                        width: "100%",
                      }}
                    >
                      <ReactPhotoSphereViewer
                        ref={photoSphereRef}
                        src={getOriginal(i.image)}
                        height={220}
                        width={"100%"}
                      ></ReactPhotoSphereViewer>
                    </div>
                  ) : (
                    <img src={getOriginal(i.image)} alt="gallery-slide" />
                  )}
                </>
              ) : (
                <video
                  autoPlay
                  muted={true}
                  controls={false}
                  playsInline={true}
                  loop
                >
                  <source src={getVideo(i.video)} />
                </video>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <img src={getOriginal(item.logo)} alt="item-logo" />
      )}

      <div
        className="inner-container"
        // onClick={() => {
        //   action(item.id);
        // }}
      >
        <span className="title">{item.title}</span>
        <span className="description">{item.description}</span>
        <div className="divider" />
      </div>
    </div>
  );
}

export default DataCoverItem;
