import React from "react";

function NothingToShow() {
  return (
    <div style={{ textAlign: "center", marginTop: 10, fontSize: 12 }}>
      Nothing to show more
    </div>
  );
}

export default NothingToShow;
