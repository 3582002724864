import produce from "immer";
import { getCountries, getCities, getNext, getStates } from "api/lib/places";

const initialState = {
  placesData: {
    countries: {
      data: [],
      loading: false,
      nextPageUrl: null,
      error: null,
    },
    cities: {
      data: [],
      loading: false,
      nextPageUrl: null,
      error: null,
    },
    states: {
      data: [],
      loading: false,
      nextPageUrl: null,
      error: null,
    },
  },
};

export const placesDataSlice = (set, get) => ({
  placesData: { ...initialState.placesData },
  fetchCountires: async () => {
    set(
      produce((state) => {
        state.placesData.countries.loading = true;
      })
    );
    getCountries()
      .then((res) => {
        set(
          produce((state) => {
            state.placesData.countries.data = res.data.data;
            state.placesData.countries.nextPageUrl = res.data.next_page_url;
          })
        );
      })
      .catch((err) => {
        console.log(err.reponse.data);
      })
      .finally(() => {
        set(
          produce((state) => {
            state.placesData.countries.loading = false;
          })
        );
      });
  },
  fetchCountiresNext: async () => {
    let url = get().placesData.countries.nextPageUrl;
    set(
      produce((state) => {
        state.placesData.countries.loading = true;
      })
    );
    getNext(url)
      .then((res) => {
        set(
          produce((state) => {
            state.placesData.countries.data = [
              ...state.placesData.countries.data,
              ...res.data.data,
            ];
            state.placesData.countries.nextPageUrl = res.data.next_page_url;
          })
        );
      })
      .catch((err) => {
        console.log(err.reponse.data);
      })
      .finally(() => {
        set(
          produce((state) => {
            state.placesData.countries.loading = false;
          })
        );
      });
  },
  searchCountries: async (query) => {
    getCountries(query)
      .then((res) => {
        set(
          produce((state) => {
            state.placesData.countries.data = res.data.data;
          })
        );
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {});
  },
  searchStates: async (country, query) => {
    getStates(country, query)
      .then((res) => {
        set(
          produce((state) => {
            state.placesData.states.data = res.data.data;
          })
        );
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {});
  },
  searchCities: async (country, state, query) => {
    getCities(country, state, query)
      .then((res) => {
        set(
          produce((state) => {
            state.placesData.cities.data = res.data.data;
          })
        );
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {});
  },
  fetchCities: async (country, state) => {
    set(
      produce((state) => {
        state.placesData.cities.loading = true;
      })
    );
    getCities(country, state)
      .then((res) => {
        set(
          produce((state) => {
            state.placesData.cities.data = res.data.data;
            state.placesData.cities.nextPageUrl = res.data.next_page_url;
          })
        );
      })
      .catch((err) => {
        console.log(err.reponse);
      })
      .finally(() => {
        set(
          produce((state) => {
            state.placesData.cities.loading = true;
          })
        );
      });
  },
  fetchCitiesNext: async () => {
    let url = get().placesData.cities.nextPageUrl;
    set(
      produce((state) => {
        state.placesData.cities.loading = true;
      })
    );
    getNext(url)
      .then((res) => {
        set(
          produce((state) => {
            state.placesData.cities.data = [
              ...state.placesData.cities.data,
              ...res.data.data,
            ];
            state.placesData.cities.nextPageUrl = res.data.next_page_url;
          })
        );
      })
      .catch((err) => {
        console.log(err.reponse);
      })
      .finally(() => {
        set(
          produce((state) => {
            state.placesData.cities.loading = true;
          })
        );
      });
  },

  fetchStates: async (country) => {
    set(
      produce((state) => {
        state.placesData.states.loading = true;
      })
    );
    getStates(country)
      .then((res) => {
        console.log(res.data);
        set(
          produce((state) => {
            state.placesData.states.data = res.data.data;
            state.placesData.states.nextPageUrl = res.data.next_page_url;
          })
        );
      })
      .catch((err) => {
        console.log(err.reponse.data);
      })
      .finally(() => {
        set(
          produce((state) => {
            state.placesData.states.loading = true;
          })
        );
      });
  },

  fetchStatesNext: async (country) => {
    let url = get().placesData.countries.nextPageUrl;
    set(
      produce((state) => {
        state.placesData.states.loading = true;
      })
    );
    getNext(url)
      .then((res) => {
        set(
          produce((state) => {
            state.placesData.cities.data = [
              ...state.placesData.states.data,
              ...res.data.data,
            ];
            state.placesData.states.nextPageUrl = res.data.next_page_url;
          })
        );
      })
      .catch((err) => {
        console.log(err.reponse.data);
      })
      .finally(() => {
        set(
          produce((state) => {
            state.placesData.states.loading = true;
          })
        );
      });
  },
});
