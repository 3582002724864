import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { getOriginal, getVideo } from "utils/imgUri";

import FSGallery from "ui/components/FSGallery";

import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

function GallerySwiper({ data: { data } }) {
  const [fsg, setFsg] = useState({ status: false, initialSlide: 0 });
  const openFSGallery = (initialSlide) => {
    setFsg({ status: true, initialSlide });
  };
  const onClose = () => {
    setFsg({ status: false, initialSlide: 0 });
  };

  const photoSphereRef = React.useRef();

  const renderSlide = (slide, index) => {
    if (slide.image) {
      if (slide.width / slide.height === 2) {
        return (
          <div
            className="App"
            style={{
              height: 360,
              width: "100%",
            }}
          >
            <ReactPhotoSphereViewer
              ref={photoSphereRef}
              src={getOriginal(slide.image)}
              height={360}
              width={"100%"}
              onClick={() => {
                openFSGallery(index);
              }}
            ></ReactPhotoSphereViewer>
          </div>
        );
      } else {
        return <img src={getOriginal(slide.image)} alt="gallery-slide" />;
      }
    } else {
      return (
        <video autoPlay muted={true} controls={false} playsInline={true} loop>
          <source src={getVideo(slide.video)} />
        </video>
      );
    }
  };

  return (
    <div className="head-swiper-container">
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {data.map((i, k) => (
          <SwiperSlide
            key={k}
            onClick={() => {
              openFSGallery(k);
            }}
          >
            {renderSlide(i, k)}
          </SwiperSlide>
        ))}
      </Swiper>
      {fsg.status && (
        <FSGallery
          data={data}
          onClose={onClose}
          initialSlide={fsg.initialSlide}
        />
      )}
    </div>
  );
}

export default GallerySwiper;
