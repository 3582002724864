import React, { useEffect, useState } from "react";
import Stories from "react-insta-stories";
import { useNavigate } from "react-router-dom";
import { getLogo, getOriginal, getVideo } from "utils/imgUri";
import AppLoading from "./AppLoading";
import CloseButton from "./buttons/CloseButton";

export default function CustomStories({
  gallery,
  selectedGallery,
  setSelectedGallery,
  logo,
}) {
  const navigate = useNavigate();
  const [gal, setGal] = useState([]);
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("timer up");
      setGal([...gallery[selectedGallery].gallery.data]);
    }, 300);

    return () => {
      setGal([]);
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [selectedGallery]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(true);

  if (!gal.length) return <AppLoading />;

  return (
    <div
      style={{
        backgroundColor: "#111111",
        paddingTop: "0.5em",
        height: "100vh",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: "0.5em",
          top: "0.5em",
          display: "flex",
          justifyContent: "flex-end",
          padding: "0.5em",
        }}
      >
        <CloseButton action={() => navigate(-1)} />
      </div>
      <div style={{ margin: "0 0.5em", gridGap: "0.2em", display: "flex" }}>
        {gallery.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedGallery(index);
            }}
            style={{
              borderRadius: "100%",
              width: "5em",
              height: "5em",
              p: "0",
              m: "0",
              border:
                index === selectedGallery
                  ? "2px solid #73B13F"
                  : "2px solid #000",
              outline: "none",
              boxShadow: "none",
              transition: "300ms all ease",
              position: "relative",
            }}
          >
            <p
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                fontSize: "0.8em",
                backgroundColor: item.event_id ? "#0E65AE" : "#AFCA09",
                borderRadius: "2%",
                padding: "0.3em",
                color: "#FFF",
                fontFamily: "Segoe UI Bold",
                position: "absolute",
              }}
            >
              {item.event_id ? "EVENT" : "NEWS"}
            </p>
            <img
              alt="logo"
              src={getLogo(logo)}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                borderRadius: "100%",
              }}
            />
          </button>
        ))}
      </div>
      <Stories
        key={selectedGallery}
        loop={false}
        stories={gal.map((item) => {
          if (item.video) {
            console.log(getVideo(item.video));
            return {
              url: getVideo(item.video),
              type: "video",
            };
          } else {
            return {
              url: getOriginal(item.image),
              type: "image",
            };
          }
        })}
        onStoryStart={(s, t) => {
          console.log(s, t);
        }}
        width={"100%"}
        height="100%"
        onAllStoriesEnd={() => navigate(-1)}
        isPaused={isPaused || !gal.length}
        currentIndex={currentIndex}
        preventDefault
      />
      <div
        style={{
          position: "absolute",
          top: 100,
          left: 0,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: window.innerWidth / 2,
            height: window.innerHeight - 100,
            background: "rgba(0,0,0,0.1)",
          }}
          onClick={() => {
            if (currentIndex !== 0) {
              setCurrentIndex((prev) => prev - 1);
            }
          }}
          onMouseDownCapture={(e) => {
            e.preventDefault();
            console.log("mouse down capture");
            setIsPaused(true);
          }}
          onMouseUpCapture={(e) => {
            e.preventDefault();
            setIsPaused(false);
          }}
        />
        <div
          style={{
            width: window.innerWidth / 2,
            height: window.innerHeight - 100,
            background: "rgba(0,0,0,0.1)",
          }}
          onClick={() => {
            if (currentIndex !== gal.length - 1) {
              setCurrentIndex((prev) => prev + 1);
            } else {
              navigate(-1);
            }
          }}
          onMouseDownCapture={(e) => {
            e.preventDefault();
            console.log("mouse down capture");
            setIsPaused(true);
          }}
          onMouseUpCapture={(e) => {
            e.preventDefault();
            setIsPaused(false);
          }}
        />
      </div>
    </div>
  );
}
