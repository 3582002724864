import { apiCall } from "api";

export const authRegister = (data) => {
  return apiCall.post(`/mobile/auth/register`, data);
};

export const authLogin = (data) => {
  return apiCall.post(`/mobile/auth/login`, data);
};

export const authLogout = () => {
  return apiCall.post(`/mobile/auth/logout`);
};

export const authUpdate = (params) => {
  return apiCall.put(`/mobile/me`, params);
};

export const getMe = () => {
  return apiCall.get(`/mobile/me`);
};

export const recoverPassword = (data) => {
  return apiCall.post(`/mobile/auth/recover`, data);
};

export const resetPassword = (data) => {
  return apiCall.post(`/mobile/auth/reset`, data);
};

export const verifyEmail = (email) => {
  return apiCall.put(`/resend/emailVerification?new_email=${email}`);
};

export const getLocationById = () => {
  return apiCall.get(`/mobile/me/location`);
};
