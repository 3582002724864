import React from "react";
import { Touchable } from "ui/components";
import { CameraOutline, ImageOutline } from "react-ionicons";

function BSAttachment({ onClose, handleOpenCamera, handleOpenPicker }) {
  return (
    <div
      style={{
        paddingTop: 30,
        paddingBottom: 15,
        height: "100%",
      }}
    >
      <div>
        {/* <Touchable action={onClose}>
          <p
            style={{
              color: palette.blue,
              fontSize: 18,
              margin: 0,
              textAlign: "right",
              marginRight: 20,
            }}
          >
            close
          </p>
        </Touchable> */}
      </div>
      <div style={styles.btnContainer}>
        <Touchable style={styles.iconContainer} action={handleOpenCamera}>
          <CameraOutline color={"#00000"} height="25px" width="25px" />
          <h3
            style={{
              fontSize: 20,
              margin: 0,
              marginLeft: 10,
              marginBottom: 8,
              padding: 0,
            }}
          >
            Camera
          </h3>
        </Touchable>
        <div
          style={{
            height: 0.5,
            width: "90%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            margin: "10px 0",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Touchable style={styles.iconContainer} action={handleOpenPicker}>
          <ImageOutline color={"#00000"} height="25px" width="25px" />
          <h3
            style={{
              fontSize: 20,
              margin: 0,
              marginLeft: 10,
              marginBottom: 8,
              padding: 0,
            }}
          >
            Photo Library
          </h3>
        </Touchable>
      </div>
    </div>
  );
}

export default BSAttachment;

const styles = {
  btnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
  },
  icon: {
    width: 40,
    height: 40,
  },
};
