import React from "react";

const SearchIcon = ({ color = "#fff", width = 30, height = 30 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40.024 39.569">
      <path
        d="M41.132,14.428A14.927,14.927,0,0,0,33.267,3.476C28.007.639,22.7.961,17.6,3.954a14.077,14.077,0,0,0-6.784,9,16.829,16.829,0,0,0-.462,5.542,12.658,12.658,0,0,0,2.065,6.209,1.756,1.756,0,0,1,.129.3c-.163.183-.311.368-.48.537-.848.857-1.7,1.717-2.56,2.561-2.046,2-4.1,3.988-6.143,6a11.15,11.15,0,0,0-1.422,1.628A3.438,3.438,0,0,0,2.4,40.1a3.473,3.473,0,0,0,4.374.4,7.857,7.857,0,0,0,1.1-.935q3.018-2.981,6.025-5.971c.807-.8,1.613-1.6,2.435-2.39a5.509,5.509,0,0,1,1.4-1.076,1.528,1.528,0,0,1,.2.048c.092.044.183.091.273.144a15.052,15.052,0,0,0,7.929,1.943,15,15,0,0,0,10.741-4.6A14.44,14.44,0,0,0,41.132,14.428ZM28.233,27.7A11.261,11.261,0,0,1,15.98,22.219a10.023,10.023,0,0,1-1.195-6.491c.057-.374.11-.752.179-1.124a10.759,10.759,0,0,1,8.221-8.242c.906-.191,1.841-.255,2.761-.379a11.209,11.209,0,0,1,10.79,8.921A10.8,10.8,0,0,1,28.233,27.7Z"
        fill={color}
        fillRule="evenodd"
        transform="translate(-1.365 -1.518)"
      />
      <path
        d="M25.965,11.177a11.276,11.276,0,0,0-3.2-3.608,5.355,5.355,0,0,0-3.074-1.1,1.623,1.623,0,0,1-.207,0,1.673,1.673,0,0,0-1.726,2.124,1.583,1.583,0,0,0,1.077,1.149,11.994,11.994,0,0,1,1.8.772,4.894,4.894,0,0,1,2.416,2.972,6.7,6.7,0,0,0,.342.862,1.154,1.154,0,0,0,.688.594,1.845,1.845,0,0,0,2.514-2.023A5.163,5.163,0,0,0,25.965,11.177Z"
        transform="translate(6.276 0.792)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SearchIcon;
