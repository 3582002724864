import React from "react";

import FormContainer from "../Auth/components/FormContainer";

const Page404 = () => {
  return (
    <div className="dis-container">
      <FormContainer>
        <h1
          style={{
            textAlign: "center",
          }}
        >
          404 Page not found
        </h1>
      </FormContainer>
    </div>
  );
};

export default Page404;
