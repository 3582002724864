import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { TextInput, Button } from "ui/components";
import Container from "../Container";
import FormContainer from "../FormContainer";
import userIco from "assets/icons/profile/user.png";
import passIco from "assets/icons/profile/pass.png";
import { useStore } from "store";

function PasswordReset() {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  const { token } = useParams();
  const { fetching } = useStore((state) => state.currentUser);
  const resetPassword = useStore((state) => state.resetPassword);

  const callBack = () => {
    navigate("/auth/login");
  };

  const onSubmit = (values, { setSubmitting }) => {
    resetPassword({ ...values }, callBack);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      token,
      password: "",
      password_confirmation: "",
    },
    validate: () => {},
    onSubmit: onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Container mounted={mounted}>
      <FormContainer>
        <TextInput
          id="email"
          name="email"
          placeholder="email"
          icoLeft={userIco}
          style={{ marginBottom: "10px" }}
          inputMode="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <TextInput
          id="password"
          name="password"
          placeholder="password"
          icoLeft={passIco}
          style={{ marginBottom: "10px" }}
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <TextInput
          id="password_confirmation"
          name="password_confirmation"
          placeholder="confirm password"
          icoLeft={passIco}
          style={{ marginBottom: "10px" }}
          type="password"
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
        />
        <div style={{ width: "100%", marginTop: "10px" }}>
          <Button
            disabled={fetching}
            label="Send"
            action={formik.handleSubmit}
          />
        </div>
      </FormContainer>
    </Container>
  );
}

export default PasswordReset;
