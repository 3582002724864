import { setTokenHeader } from "api";
import {
  authLogin,
  authRegister,
  authLogout,
  authUpdate,
  resetPassword,
  recoverPassword,
  getMe,
  verifyEmail,
} from "api/lib/user";

const initialState = {
  isAuthenticated: localStorage.getItem("jwtToken") ? true : false,
  user: {},
  fetching: false,
  fetched: false,
  error: null,
};

export const currentUserSlice = (set, get) => ({
  currentUser: initialState,
  setCurrentUser: (currentUser) => {
    console.log(currentUser);
    set(() => ({
      currentUser: {
        ...currentUser,
        fetched: false,
        fetching: true,
        isAuthenticated: true,
      },
    }));
  },
  loginUser: async (data) => {
    set((state) => ({
      currentUser: {
        ...state.currentUser,
        fetched: false,
        fetching: true,
      },
    }));
    authLogin(data)
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("jwtToken", res.data.access_token);
        setTokenHeader(res.data.access_token);
        set((state) => ({
          currentUser: {
            isAuthenticated: true,
            user: res.data.user,
            fetching: false,
            fetched: true,
            error: null,
          },
        }));
      })
      .catch((err) => {
        set((state) => ({
          currentUser: {
            ...state.currentUser,
            fetching: false,
            fetched: true,
            error: err.response?.data || "unknown error",
          },
        }));
      });
  },
  logOutUser: async () => {
    localStorage.removeItem("jwtToken");
    authLogout().then((res) => {
      setTokenHeader();
      set((state) => ({
        currentUser: { ...initialState, isAuthenticated: false },
      }));
    });
  },
  registerUser: async (data) => {
    set((state) => ({
      currentUser: {
        ...state.currentUser,
        fetched: false,
        fetching: true,
      },
    }));
    authRegister(data)
      .then((res) => {
        localStorage.setItem("jwtToken", res.data.access_token);
        setTokenHeader(res.data.access_token.token);
        set((state) => ({
          currentUser: {
            isAuthenticated: true,
            user: res.data.user,
            fetching: false,
            fetched: true,
            error: null,
          },
        }));
      })
      .catch((err) => {
        set((state) => ({
          currentUser: {
            ...state.currentUser,
            fetching: false,
            fetched: true,
            error: err.response?.data || "unknown error",
          },
        }));
      });
  },
  fetchUserData: async (callback) => {
    set((state) => ({
      currentUser: { ...state.currentUser, fetching: true },
    }));

    getMe()
      .then((res) => {
        set((state) => {
          return {
            currentUser: {
              ...state.currentUser,
              isAuthenticated: true,
              fetching: false,
              fetched: true,
              user: res.data.user,
            },
          };
        });
        if (
          !res.data.user.name ||
          res.data.user.name === "" ||
          !res.data.user.surname ||
          res.data.user.surname === ""
        ) {
          callback();
        }
      })
      .catch((err) => {
        set((state) => ({
          currentUser: {
            ...initialState,
            isAuthenticated: false,
            fetching: false,
            error: err.response,
          },
        }));
      });
  },
  updateUser: async (params, callback) => {
    set((state) => ({
      currentUser: {
        ...state.currentUser,
        fetched: false,
        fetching: true,
      },
    }));
    let prevMail = get().currentUser.user.email;
    if (prevMail !== params.email) {
      get().verifyEmail(params, callback);
    } else {
      authUpdate(params)
        .then((res) => {
          set((state) => ({
            currentUser: {
              ...state.currentUser,
              fetching: false,
              fetched: true,
              error: null,
            },
          }));

          get().fetchUserData();
          callback();
        })
        .catch((err) => {
          console.log(err.response.data);
          set((state) => ({
            currentUser: {
              ...state.currentUser,
              fetching: false,
              fetched: true,
              error: err.response?.data || "unknown error",
            },
          }));
        });
    }
  },
  recoverPassword: async (data, callBack) => {
    set((state) => ({
      currentUser: {
        ...state.currentUser,
        fetched: false,
        fetching: true,
      },
    }));
    recoverPassword(data)
      .then((res) => {
        if (res.data.success) {
          callBack();
          set((state) => ({
            currentUser: {
              ...state.currentUser,
              fetching: false,
              fetched: true,
              error: null,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err.response?.data);
        set((state) => ({
          currentUser: {
            ...state.currentUser,
            fetching: false,
            fetched: true,
            error: err.response?.data || "unknown error",
          },
        }));
      });
  },
  resetPassword: async (data, callBack) => {
    set((state) => ({
      currentUser: {
        ...state.currentUser,
        fetched: false,
        fetching: true,
      },
    }));
    resetPassword(data)
      .then((res) => {
        set((state) => ({
          currentUser: {
            ...state.currentUser,
            fetched: true,
            fetching: false,
          },
        }));
        if (res.data?.success) {
          callBack();
        }
      })
      .catch((err) => {
        console.log(err.response?.data);
        set((state) => ({
          currentUser: {
            ...state.currentUser,
            fetching: false,
            fetched: true,
            error: err.response?.data || "unknown error",
          },
        }));
      });
  },
  verifyEmail: async (params, callback) => {
    verifyEmail(params.email)
      .then((res) => {
        console.log(res);
        authUpdate(params)
          .then((res) => {
            set((state) => ({
              currentUser: {
                ...state.currentUser,
                fetching: false,
                fetched: true,
                error: null,
              },
            }));
            get().fetchUserData();
            callback();
          })
          .catch((err) => {
            console.log(err.response.data);
            set((state) => ({
              currentUser: {
                ...state.currentUser,
                fetching: false,
                fetched: true,
                error: err.response?.data || "unknown error",
              },
            }));
          });
      })
      .catch((err) => console.log(err.response));
  },
  clearUserStoreError: () => {
    set((state) => ({
      currentUser: {
        ...state.currentUser,
        error: null,
      },
    }));
  },
});
