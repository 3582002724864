import { toast } from "react-hot-toast";

export const webShare = async (title, text, url) => {
  if (!("share" in navigator)) {
    console.error("Error: Unsupported feature: navigator.share");
    navigator.clipboard.writeText(window.location.href);
    toast("Link copied to clipboard", {
      style: {
        background: "#73A580",
        color: "black",
        fontSize: "14px",
      },
    });
    return;
  }

  try {
    await navigator.share({ title, text, url: window.location.href });
  } catch (error) {
    console.error(`Error sharing: ${error}`);
    return;
  }

  console.log("Successfully sent share");
};
