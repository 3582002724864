import React from "react";
import { Touchable } from "ui/components";
import { ChevronBackOutline, InformationCircleOutline } from "react-ionicons";

function Header({ info, chatName, handleInfo }) {
  return (
    <div className="header" style={{ zIndex: 5 }}>
      {/* <BackButton styleType="light" /> */}
      <Touchable
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: 5,
          height: 30.9,
        }}
        action={() => {
          window.history.back();
        }}
      >
        <ChevronBackOutline
          color={"#00000"}
          height="30.9px"
          width="25px"
          style={{ verticalAlign: "middle" }}
        />
      </Touchable>
      <div
        style={{
          position: "relative",
          flexGrow: 1,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          maxWidth: "80%",
          marginRight: "auto",
        }}
      >
        <p
          style={{
            fontSize: 16,
            verticalAlign: "middle",
            flexGrow: 1,
            overflow: "hidden",
            maxWidth: "100%",
          }}
        >
          {chatName
            ? chatName
            : info?.data?.company?.name
            ? info?.data?.company?.name
            : ""}
        </p>
        {/* {parts?.length === 0 && <div style={{ width: 50 }} />} */}
      </div>
      <Touchable
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 30.9,
        }}
        action={handleInfo}
      >
        <InformationCircleOutline
          color={"#3B7BE0"}
          height="30.9px"
          width="25px"
          style={{ verticalAlign: "middle" }}
        />
      </Touchable>
    </div>
  );
}

export default Header;
