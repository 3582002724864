import { mapsApi, apiCall } from "api";

const API_KEY = "AIzaSyBRzKZl0UlHl7cA4JfxmxKwBmW-koFqKnk";

export function getRoute(origin, destination) {
  return mapsApi.get(
    `/directions/json?origin=${origin.latitude},${origin.latitude}&destination=${destination.latitude},${destination.latitude}&key=${API_KEY}`
  );
}

export function getMapData(data) {
  const { latitude, longitude, zoom = 20 } = data;
  return apiCall.get(
    `/mobile/map?latitude=${latitude}&longitude=${longitude}&orderBy=distance&zoom=${zoom}`
  );
}
