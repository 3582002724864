import React, { useEffect, useRef } from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { getOriginal, getVideo } from "utils/imgUri";

import { Touchable } from "ui/components";
import closeBtn from "assets/icons/close.png";

function FSGallery({ data, onClose, initialSlide }) {
  const photoSphereRef = useRef();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div id="fs-gallery">
      <Touchable action={onClose} className="close-btn">
        <img src={closeBtn} alt="modal-close-btn" />
      </Touchable>
      <Swiper
        initialSlide={initialSlide}
        pagination={{
          clickable: true,
        }}
        //   className="mySwiper"
      >
        {data.map((i, k) => (
          <SwiperSlide key={k}>
            {i.image ? (
              <>
                {i.width / i.height === 2 ? (
                  <div
                    className="App"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <ReactPhotoSphereViewer
                      ref={photoSphereRef}
                      src={getOriginal(i.image)}
                      height={"100%"}
                      width={"100%"}
                    ></ReactPhotoSphereViewer>
                  </div>
                ) : (
                  <img src={getOriginal(i.image)} alt="gallery-slide" />
                )}
              </>
            ) : (
              <video
                autoPlay
                muted={true}
                controls={false}
                playsInline={true}
                loop
              >
                <source src={getVideo(i.video)} />
              </video>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default FSGallery;
