import React, { useMemo, useState } from "react";

import { TextInput, Button } from "ui/components";
import userIco from "assets/icons/profile/user.png";
import phoneIco from "assets/icons/profile/phone.png";
import mailIco from "assets/icons/profile/mail.png";
import SelectSearch from "./SelectSearch";
import { useStore } from "store";

import infoIcon from "assets/icons/infoIcon.png";

function Form({
  formik,
  countries,
  cities,
  states,
  selectedCountry,
  setSelectedCountry,
  selectedCity,
  setSelectedCity,
  selectedState,
  setSelectedState,
  formErrors = {},
}) {
  const countriesMemo = useMemo(() => {
    let arr = [];
    for (let i = 0; i < countries.data?.length; i++) {
      arr.push({
        name: countries.data[i].name,
        value: countries.data[i].iso2,
      });
    }
    return arr;
  }, [countries]);

  const citiesMemo = useMemo(() => {
    let arr = [];
    for (let i = 0; i < cities.data?.length; i++) {
      arr.push({
        name: cities.data[i].name,
        value: cities.data[i].name,
      });
    }
    return arr;
  }, [cities]);

  const statesMemo = useMemo(() => {
    let arr = [];
    for (let i = 0; i < states.data.length; i++) {
      arr.push({
        name: states.data[i].name,
        value: states.data[i].state_code,
      });
    }
    return arr;
  }, [states]);

  const [countryText, setCountryText] = useState(selectedCountry);
  const [stateText, setstateText] = useState(selectedCountry);
  const [cityText, setcityText] = useState(selectedCountry);

  const fetchCountiresNext = useStore((state) => state.fetchCountiresNext);
  const countriesNext = useStore(
    (state) => state.placesData.countries.nextPageUrl
  );

  const searchCountries = useStore((state) => state.searchCountries);
  const searchStates = useStore((state) => state.searchStates);
  const searchCities = useStore((state) => state.searchCities);

  const onEndReach = (refer) => {
    if (refer === "countries" && countriesNext) {
      fetchCountiresNext();
    }
    if (refer === "states" && countriesNext) {
      fetchCountiresNext();
    }
    if (refer === "cities" && countriesNext) {
      fetchCountiresNext();
    }
  };

  const onChangeText = (text, refer) => {
    if (refer === "countries") {
      setCountryText(text);
      searchCountries(text);
    }
    if (refer === "states") {
      setstateText(text);
      searchStates(selectedCountry, text);
    }
    if (refer === "cities") {
      setcityText(text);
      searchCities(selectedCountry, selectedState, text);
    }
  };

  const renderErrorMsg = (field) => {
    // if (!formErrors) return null;
    if (!formErrors?.errors) return null;
    if (formErrors?.errors[field]) {
      return (
        <>
          {formErrors?.errors[field].map((i, k) => (
            <div
              key={k}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: 2,
              }}
            >
              <img
                src={infoIcon}
                alt="error-info"
                style={{ height: 15, width: 15, marginRight: 5, marginTop: 4 }}
              />
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 14,
                  color: "#ED5465",
                }}
              >
                {i}
              </p>
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <div
      className="profile-edit-form"
      style={{ overflowY: "auto", height: window.innerHeight - 80 }}
    >
      {renderErrorMsg("name")}
      <TextInput
        id="name"
        name="name"
        placeholder="name"
        icoLeft={userIco}
        style={{ marginBottom: "10px" }}
        inputMode="name"
        type="name"
        value={formik.values.name}
        onChange={formik.handleChange}
      />
      {renderErrorMsg("surname")}
      <TextInput
        id="surname"
        name="surname"
        placeholder="surname"
        icoLeft={userIco}
        style={{ marginBottom: "10px" }}
        value={formik.values.surname}
        onChange={formik.handleChange}
      />
      {renderErrorMsg("phone_number")}
      <TextInput
        id="phone_number"
        name="phone_number"
        placeholder="phone_number"
        icoLeft={phoneIco}
        style={{ marginBottom: "10px" }}
        inputMode="tel"
        type="tel"
        value={formik.values.phone_number}
        onChange={formik.handleChange}
      />
      {renderErrorMsg("email")}
      <TextInput
        id="email"
        name="email"
        placeholder="email"
        icoLeft={mailIco}
        style={{ marginBottom: "10px" }}
        inputMode="tel"
        type="tel"
        value={formik.values.email}
        onChange={formik.handleChange}
      />
      {renderErrorMsg("street")}
      <TextInput
        id="street"
        name="street"
        placeholder="street"
        icoLeft={userIco}
        style={{ marginBottom: "10px" }}
        value={formik.values.street}
        onChange={formik.handleChange}
      />
      {renderErrorMsg("country")}
      <SelectSearch
        options={countriesMemo}
        name="country"
        placeholder="Choose your country"
        value={selectedCountry}
        onChange={(res) => {
          // formik.setFieldValue("country", res);
          setSelectedCountry(res);
        }}
        inputVal={countryText}
        onInputValChange={onChangeText}
        icoLeft={userIco}
        onEndReach={onEndReach}
        refer={"countries"}
      />
      {renderErrorMsg("state")}
      <SelectSearch
        options={statesMemo}
        name="state"
        placeholder="Choose your state"
        value={selectedCity}
        onChange={(res) => {
          console.log(res);
          setSelectedState(res);
        }}
        inputVal={stateText}
        onInputValChange={onChangeText}
        icoLeft={userIco}
        onEndReach={onEndReach}
        refer={"states"}
      />
      {renderErrorMsg("city")}
      <SelectSearch
        options={citiesMemo}
        name="city"
        placeholder="Choose your city"
        value={selectedCity}
        onChange={(res) => {
          setSelectedCity(res);
          const city = cities.data.find((i) => i.name === res);
          formik.setFieldValue("city_id", city.id);
        }}
        inputVal={cityText}
        onInputValChange={onChangeText}
        icoLeft={userIco}
        onEndReach={onEndReach}
        refer={"cities"}
      />

      {renderErrorMsg("postal_code")}
      <TextInput
        id="postal_code"
        name="postal_code"
        placeholder="post code"
        icoLeft={userIco}
        style={{ marginBottom: "10px" }}
        value={formik.values.postal_code}
        onChange={formik.handleChange}
      />
      <div style={{ width: "100%", marginTop: "30px" }}>
        <Button
          type="secondary"
          //  disabled={fetching}
          label="Save"
          action={formik.handleSubmit}
        />
      </div>
    </div>
  );
}

export default Form;
