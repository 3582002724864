import React, { useState } from "react";
import CalendarEventItem from "./CalendarEventItem";
import Container from "./Container";
import DatePicker from "./DatePicker";

function BsCalendar({ data }) {
  const [pickerActive, setPickerActive] = useState(false);
  const [provider, setProvider] = useState(null);

  const handleOnPress = (ref) => {
    setProvider(ref);
    if (data.recurrence && data.recurrence_range.length > 0) {
      setPickerActive(true);
    } else if (data?.availability?.length === 1) {
      if (data?.availability[0]?.links) {
        window.open(data.availability[0].links[ref]);
      } else {
        console.log("no links");
      }
    } else if (data?.availability?.length > 1) {
      setPickerActive(true);
    }
  };
  const onClosePicker = () => {
    setPickerActive(false);
    setProvider(null);
  };

  const onChooseDate = (date) => {
    data.recurrence_range.map((i) => {
      let nd = new Date(i.start_at);
      nd.setHours(0, 0, 0, 0);
      let targetDate = nd.toISOString();
      if (targetDate === date.toISOString()) {
        window.open(i.links[provider]);
      }
      return null;
    });
    onClosePicker();
  };

  return (
    <Container>
      <CalendarEventItem
        title={"Google"}
        action={() => {
          handleOnPress("google_calendar");
        }}
      />
      <CalendarEventItem
        title={"Yahoo"}
        action={() => {
          handleOnPress("yahoo_calendar");
        }}
      />
      <CalendarEventItem
        title={"Apple"}
        action={() => {
          handleOnPress("apple_calendar");
        }}
      />
      <CalendarEventItem
        title={"Microsoft 365"}
        action={() => {
          handleOnPress("web_live_outlook_calendar");
        }}
      />
      <CalendarEventItem
        title={"Outlook"}
        action={() => {
          handleOnPress("web_office_outlook_calendar");
        }}
      />
      <DatePicker
        active={pickerActive}
        onCloseModal={onClosePicker}
        dates={
          data.recurrence_range.length > 0
            ? data.recurrence_range
            : data.availability
        }
        onChooseDate={onChooseDate}
      />
    </Container>
  );
}

export default BsCalendar;
