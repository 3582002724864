import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";
import { DropDownButton, DDItem, BackButton, Touchable } from "ui/components";
import { getAvatar } from "utils/imgUri";

import chatIcon from "assets/icons/chat/createChatIcon@3x.png";
function ProfileHeader({ setMainContent, type = "dropdown" }) {
  const { avatar, name, email } = useStore((state) => state.currentUser.user);
  const logOutUser = useStore((state) => state.logOutUser);
  const recoverPassword = useStore((state) => state.recoverPassword);
  const navigate = useNavigate();

  const logOut = () => {
    logOutUser();
    setMainContent("tabs");
  };

  const handleChat = () => {
    navigate("chats");
  };

  const callBack = () => {
    // logOutUser();
    setMainContent("tabs");
  };

  const handleResetPassword = () => {
    recoverPassword({ email }, callBack);
  };

  return (
    <div className="profile-header">
      {type === "back" && <BackButton styleType="light" />}
      <div className="avatar-container">
        <img className="avatar" src={getAvatar(avatar)} alt="user-avatar" />
        <div className="edit-avatar-container">
          <p>Edit</p>
        </div>
      </div>
      <span>{name}</span>
      {type !== "back" && (
        <Touchable className="chat-ico" action={handleChat}>
          <img className="chat-icon" src={chatIcon} alt="new-chat" />
        </Touchable>
      )}
      {type === "dropdown" && (
        <DropDownButton>
          <DDItem
            noBorder
            label="Edit Profile"
            action={() => navigate("edit")}
          />
          <DDItem label="Reset password" action={handleResetPassword} />
          <DDItem label="Logout" action={logOut} />
        </DropDownButton>
      )}
    </div>
  );
}

export default ProfileHeader;
