import Container from "components/Auth/components/Container";
import ProfileHeader from "components/Main/components/Content/Profile/components/StickyHeader/components/ProfileHeader";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";
import Form from "./Form";
import HeaderContainer from "./HeaderContainer";

function EditProfile() {
  const [mounted, setMounted] = useState(false);

  const fetchCountires = useStore((state) => state.fetchCountires);
  const fetchCities = useStore((state) => state.fetchCities);
  const fetchStates = useStore((state) => state.fetchStates);

  const countries = useStore((state) => state.placesData.countries);
  const cities = useStore((state) => state.placesData.cities);
  const states = useStore((state) => state.placesData.states);

  useEffect(() => {
    setMounted(true);
    fetchCountires();
    // eslint-disable-next-line
  }, []);

  let navigate = useNavigate();
  const goBack = () => navigate(-1);

  const { fetched, error: formErrors } = useStore((state) => state.currentUser);
  const user = useStore((state) => state.currentUser.user);
  const updateUser = useStore((state) => state.updateUser);
  const {
    avatar,
    name,
    surname,
    phone_number,
    email,
    street,
    state,
    city,
    city_id,
    country,
    postal_code,
  } = user;

  const [selectedCountry, setSelectedCountry] = useState(country);
  const [selectedCity, setSelectedCity] = useState(city);
  const [selectedState, setSelectedState] = useState(state);

  const onSubmit = (values, { setSubmitting }) => {
    updateUser(values, goBack);
  };

  const formik = useFormik({
    initialValues: {
      avatar,
      name,
      surname,
      phone_number,
      email,
      street,
      state: selectedState,
      city: selectedCity,
      city_id,
      country: selectedCountry,
      postal_code,
      timezone: "asia/tbilisi",
    },
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
      fetchCities(selectedCountry, selectedState);
    }
    // eslint-disable-next-line
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    if (fetched) {
      formik?.setSubmitting(false);
    }
    // eslint-disable-next-line
  }, [fetched]);

  return (
    <Container mounted={mounted}>
      <HeaderContainer>
        <ProfileHeader type="back" />
      </HeaderContainer>
      <Form
        {...{
          formik,
          countries,
          cities,
          states,
          selectedCountry,
          setSelectedCountry,
          selectedCity,
          setSelectedCity,
          selectedState,
          setSelectedState,
          formErrors,
        }}
      />
    </Container>
  );
}

export default EditProfile;
