import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";

import { useStore } from "store";
import { sendMessage } from "api/lib/chat";

import { BSModal, Touchable } from "ui/components";
import Layout from "./components/Layout";
import Header from "./components/Header";
import Chat from "./components/Chat";
import BSAttachment from "./components/BSAttachment";
import { uploadMedia } from "api/lib/upload";

import closeBtn from "assets/icons/close.png";
import iconSend from "assets/icons/chat/iconSend.png";
import { palette } from "ui";
import BsMessageOptions from "./components/BsMessageOptions";
import BsEditMessage from "./components/BsEditMessage";
import InfoScreen from "./components/InfoScreen";

function ChatScreen() {
  let navigate = useNavigate();

  let { id, companyId } = useParams();
  const getChatInfo = useStore((state) => state.getChatInfo);
  const fetchMessages = useStore((state) => state.fetchMessages);
  const fetchMessagesNext = useStore((state) => state.fetchMessagesNext);
  const addMessage = useStore((state) => state.addMessage);
  const updateApprove = useStore((state) => state.updateApprove);
  const bulkDeleteConversations = useStore(
    (state) => state.bulkDeleteConversations
  );

  useEffect(() => {
    getChatInfo(id, companyId);
    fetchMessages(id, companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getChatInfo(id, companyId);
    }, 120000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { messages, info } = useStore((state) => state.chat);
  const canFetchNext = useStore((state) => state.chat.messages.canFetchNext);
  const userID = useStore((state) => state.currentUser.user.id);
  const user = { id: userID };

  const conversations = useStore((state) => state.conversations.body);
  const chatName = conversations?.data?.find((i) => i.id === id)?.name;

  const cameraInputRef = useRef();
  const fileInputRef = useRef();

  const [infoScreen, setInfoScreen] = useState(false);

  const [bs, setBs] = useState(false);
  const [bsMedia, setBsMedia] = useState(false);

  const [bsMessageOptions, setBsMessageOptions] = useState(false);
  const [activeMessage, setActiveMessage] = useState(null);

  const [bsEditMessage, setBsEditMessage] = useState(false);

  const [choosenFile, setChooseFile] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(null);

  const onEndReached = () => {
    setTimeout(() => {
      if (canFetchNext && !messages.loading && !messages.fetchingNext) {
        fetchMessagesNext();
      }
    }, 500);
  };

  const handleInfo = () => {
    setInfoScreen(true);
  };

  const handleDeleteChat = () => {
    bulkDeleteConversations(id, () => {
      navigate("/chats");
    });
  };

  const handleSend = async (message) => {
    let draftId = uuidv4();
    addMessage(message, draftId);
    sendMessage(id, {
      message: message.text,
      company_id: companyId,
    })
      .then((res) => {
        console.log("from local", message);
        console.log("from res", res.data);

        // addMessage(res.data);
        updateApprove(draftId, res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setMediaLoading(false);
        console.log("make draft null");
        setChooseFile(null);
      });
  };

  const handleOpenBSMedia = () => {
    setBsMedia(true);
  };

  const handleOpenCamera = () => {
    cameraInputRef.current?.click();
  };
  const handleOpenPicker = () => {
    fileInputRef.current?.click();
  };

  const handleMediaUpload = () => {
    setMediaLoading(true);
    const formData = new FormData();
    formData.append("image[0]", choosenFile);
    setBsMedia(false);
    uploadMedia(formData)
      .then((res) => {
        let obj = {
          text: res.data.images[0],
          message: res.data.images[0],
          sender_mobile_user_id: user.id,
          draft: true,
        };
        handleSend(obj);
      })
      .catch((error) => {
        console.error("Error:", error);
        setChooseFile(null);
        setMediaLoading(false);
      });
  };

  const onAttachmentPress = () => {
    if (isMobile) {
      setBs(true);
    } else {
      handleOpenPicker();
    }
  };

  useEffect(() => {
    if (choosenFile) {
      console.log("here");
      handleOpenBSMedia();
      setBs(false);
    }
  }, [choosenFile]);

  useEffect(() => {
    if (!bsMedia) {
      // setChooseFile(null);
    }
  }, [bsMedia]);

  return (
    <Layout>
      <input
        ref={cameraInputRef}
        style={{ display: "none" }}
        accept="image/*"
        id="icon-button-file"
        type="file"
        capture="environment"
        onClick={(res) => {
          res.target.value = null;
        }}
        onChange={(e) => {
          setChooseFile(e.target.files[0]);
        }}
      />
      <input
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        id="icon-button-file"
        type="file"
        // capture="environment"
        onChange={(e) => {
          setChooseFile(e.target.files[0]);
        }}
      />
      <Header
        info={info}
        user={user}
        chatName={chatName}
        handleInfo={handleInfo}
      />
      <Chat
        bsMessageOptions={bsMessageOptions}
        setBsMessageOptions={setBsMessageOptions}
        setActiveMessage={setActiveMessage}
        setBsEditMessage={setBsEditMessage}
        messages={messages?.body?.data}
        user={user}
        onAttachmentPress={onAttachmentPress}
        onSendPress={handleSend}
        onEndReached={onEndReached}
        choosenFile={choosenFile}
        mediaLoading={mediaLoading}
      />
      {bs && (
        <BSModal
          state={bs.active}
          setState={setBs}
          shouldRenderHeader={false}
          renderBackdrop={true}
          height={"150px"}
        >
          <BSAttachment
            onClose={() => setBs(false)}
            handleOpenCamera={handleOpenCamera}
            handleOpenPicker={handleOpenPicker}
          />
        </BSModal>
      )}
      {bsMessageOptions && (
        <BSModal
          state={bsMessageOptions}
          setState={setBsMessageOptions}
          shouldRenderHeader={false}
          height={activeMessage.type === "image" ? "80px" : "170px"}
          renderBackdrop
        >
          <BsMessageOptions
            handleClose={() => {
              setBsMessageOptions(false);
            }}
            setActiveMessage={setActiveMessage}
            setBsEditMessage={setBsEditMessage}
            activeMessage={activeMessage}
            companyId={companyId}
          />
        </BSModal>
      )}
      {bsEditMessage && (
        <BSModal
          state={bsEditMessage}
          setState={setBsEditMessage}
          height={"95%"}
          renderBackdrop
          headerTitle={"Edit Message"}
        >
          <BsEditMessage
            handleClose={() => {
              setBsEditMessage(false);
              setActiveMessage(null);
            }}
            activeMessage={activeMessage}
            setBsEditMessage={setBsEditMessage}
            companyId={companyId}
          />
        </BSModal>
      )}
      {bsMedia && (
        <BSModal
          state={bsMedia}
          setState={setBsMedia}
          shouldRenderHeader={false}
          renderBackdrop={true}
          height={"150px"}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              padding: 20,
              position: "relative",
            }}
          >
            <Touchable
              style={{
                position: "absolute",
                backgroundColor: palette.blue,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 40,
                height: 40,
                borderRadius: "50%",
                top: -20,
                right: 20,
              }}
              action={() => {
                setBsMedia(false);
              }}
            >
              <img
                src={closeBtn}
                alt="close-btn"
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </Touchable>
            {choosenFile && (
              <img
                src={URL.createObjectURL(choosenFile)}
                alt="choosen file"
                style={{
                  width: 100,
                  height: 100,
                  maxHeight: 100,
                  maxWidth: 100,
                  objectFit: "cover",
                  borderRadius: 10,
                }}
              />
            )}
            <Touchable
              className="send-btn"
              style={{
                backgroundColor: palette.blue,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 45,
                height: 45,
                borderRadius: "50%",
              }}
              action={handleMediaUpload}
            >
              <img
                src={iconSend}
                alt="send"
                style={{
                  width: 25,
                  height: 25,
                  marginRight: 3,
                  marginTop: 2,
                }}
              />
            </Touchable>
          </div>
        </BSModal>
      )}
      <InfoScreen
        active={infoScreen}
        setActive={setInfoScreen}
        info={info}
        name={chatName}
        user={user}
        handleDeleteChat={handleDeleteChat}
      />
    </Layout>
  );
}

export default ChatScreen;
