import React from "react";

const MapIcon = ({ color = "#fff", width = 30, height = 30 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 44.061 42.715">
      <path
        d="M22.053 4.496a5.139 5.139 0 1 1-5.212 5.14 5.169 5.169 0 0 1 5.212-5.14Zm5.482 17.779c.572-1.139 1.114-2.169 1.615-3.256a24.8 24.8 0 0 0 2.447-8.534 10.4 10.4 0 0 0-.084-2.444 9.288 9.288 0 0 0-2.181-4.679 9.5 9.5 0 0 0-5.871-3.26c-.39-.06-.575-.054-1.158-.1-.167.005-.342 0-.509 0-.192.012-.151 0-.226.007a9.425 9.425 0 0 0-6.226 2.7 9.254 9.254 0 0 0-2.888 6.236 13.949 13.949 0 0 0 .327 3.774 30.272 30.272 0 0 0 2.338 6.742c.443.934.922 1.675 1.421 2.814l.979 1.711a45.073 45.073 0 0 0 3.06 4.578c.468.65.956 1.253 1.437 1.9.028-.022.035.025.04.018a62.806 62.806 0 0 0 4.494-6.493Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        fill={color}
        stroke={color}
        strokeMiterlimit={10}
        strokeWidth={1.655}
        d="m32.684 24.471-6.043 16.536"
      />
      <path
        d="M3.913 31.901s3.448-2.812 10.782-.988 10.36 3.822 14.968 1.824"
        fill={color}
        stroke={color}
        strokeMiterlimit={10}
        strokeWidth={1.655}
      />
      <path
        d="m37.013 26.182 3.2 13.11H3.85l3.2-13.11h29.963m.258-3.423H6.788a3.26 3.26 0 0 0-3 2.359L.067 40.355a1.822 1.822 0 0 0 1.854 2.36H42.14a1.822 1.822 0 0 0 1.854-2.36l-3.718-15.236a3.258 3.258 0 0 0-3-2.36Z"
        fill={color}
      />
    </svg>
  );
};

export default MapIcon;
