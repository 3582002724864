import React, { useMemo } from "react";
import Modal from "react-modal";
import { DayPicker } from "react-day-picker";

import "react-day-picker/dist/style.css";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    //  height: "100%",
    //  width: "100%",
    zIndex: 1000,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};
Modal.setAppElement("#root");

function DatePicker({ active, onCloseModal, dates, onChooseDate }) {
  const availableDates = useMemo(() => {
    let newArr = dates.map((i) => {
      let nd = new Date(i.start_at);
      nd.setHours(0, 0, 0, 0);
      return nd.toISOString();
    });
    return newArr;
  }, [dates]);

  return (
    <Modal
      isOpen={active}
      onRequestClose={onCloseModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <DayPicker
        mode="single"
        onSelect={(res) => onChooseDate(res)}
        hidden={(day) => {
          if (availableDates.indexOf(day.toISOString()) > -1) {
            return false;
          } else return true;
        }}
      />
    </Modal>
  );
}

export default DatePicker;
